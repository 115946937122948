import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, fromEvent, iif, interval, merge, Observable, of, throwError, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  map,
  mergeMap,
  pairwise,
  retryWhen,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throttle,
  withLatestFrom,
} from 'rxjs/operators';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import localforage from 'localforage';
import { getSelectors, ROUTER_NAVIGATED } from '@ngrx/router-store';
import * as actions from './home.actions';
import {
  RspndrAlarm,
  RspndrException,
  RspndrGuard,
  RspndrSimpleSubscriberDto,
} from '../@core/@models/rspndr';
import { Page, RspndrPortalApi } from '../@core/@api/portal';
import {
  getHomeActiveAlarmsLastModifiedAt,
  State,
  getHomeGuardsLastModifiedAt,
  getHomeGuards,
  getHomeActiveAlarms,
  getHomeActiveAlarmsTableError,
  getAppBrowserPreferences,
} from '../@core/reducers';
import {
  ACTIVE_ALARMS_POLL_INTERVAL_MS,
  BROWSER_INACTIVE_TIME_LIMIT_SECONDS,
  GUARDS_POLL_INTERVAL_MS,
  POSITION_INACTIVE_THRESHOLD_MS,
} from '../@core/@models/constants';
import { RspndrUserApi } from '../@core/@api/user';
import { RspndrNotificationPreferences } from './home-alarms/manage-alarm-notifications-dialog/manage-alarm-notifications-dialog.component';
import { RxjsUtils } from '../@core/rxjs-utils';
import { InactiveWarningDialogComponent } from './inactive-warning-dialog/inactive-warning-dialog.component';
import { RspndrAuthService } from '../@core/@services/auth.service';
import { RspndrIntegrationApi } from '../@core/@api/integration';
import { RspndrAlarmApi } from '../@core/@api/alarm';

@Injectable()
export class HomeEffects {
  pollingInitialLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        actions.HOME_ACTION_TYPES.POLLING_INITIAL_LOAD,
        actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_POLLER_RESTART,
        actions.HOME_ACTION_TYPES.GUARDS_POLLER_RESTART,
      ),
      withLatestFrom(this.authService.isMC$, this.authService.isDealer$),
      mergeMap(([, isMC, isDealer]) =>
        this.portalApi.activeAlarms().pipe(
          mergeMap((alarmsRes) => {
            let assignedToAlarmUsernames: string[] = [];
            if (isMC || isDealer) {
              assignedToAlarmUsernames = alarmsRes[0].payload.activeAlarms
                .filter((alarm) => !!alarm.assignedTo?.username)
                .map((alarm) => alarm.assignedTo?.username);
            }
            return this.portalApi.guards(assignedToAlarmUsernames).pipe(
              mergeMap((guardsRes) => [
                actions.pollingInitialLoadSuccessAction({
                  activeAlarmsResponse: alarmsRes[0].payload,
                  guardsResponse: guardsRes[0].payload,
                }),
                actions.clearNotificationsAction(),
                actions.inactiveBrowserListenerAction(),
                actions.activeAlarmsPollerAction(),
                actions.guardsPollerAction(),
              ]),
            );
          }),
          retryWhen(this.rxjsUtils.pollingBackoffStrategy()),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load initial data: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.pollingInitialLoadFailAction());
          }),
        ),
      ),
    ),
  );

  clearExpiredNotificationPreferencesFromLocalStorage$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.HOME_ACTION_TYPES.CLEAR_NOTIFICATIONS),
        withLatestFrom(this.store.select(getHomeActiveAlarms)),
        tap(([, activeAlarms]) => {
          const activeAlarmIds = new Set(activeAlarms.map((alarm) => alarm.id));
          localforage
            .getItem<RspndrNotificationPreferences>('NOTIFICATION-PREFERENCES')
            .then((entries) => {
              const newEntries: RspndrNotificationPreferences = {};
              if (entries) {
                Object.entries(entries).forEach(([key, val]) => {
                  if (activeAlarmIds.has(key)) {
                    newEntries[key] = val;
                  }
                });
              }
              localforage.setItem('NOTIFICATION-PREFERENCES', newEntries);
            });
        }),
      ),
    { dispatch: false },
  );

  displayNewNotifications$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_SUCCESS),
        withLatestFrom(
          this.store.select(getHomeActiveAlarms),
          this.store.select(getAppBrowserPreferences),
          this.authService.isRootAdmin$,
          this.authService.isOutsideOps$,
        ),
        pairwise(),
        tap(
          async ([
            [, oldActiveAlarms],
            [, newActiveAlarms, browserPrefs, isRootAdmin, isOutsideOps],
          ]) => {
            if (isRootAdmin || isOutsideOps) {
              // build a dictionary of current exceptions by alarm ID
              const oldActiveAlarmsExceptions = {};
              oldActiveAlarms.forEach((oldActiveAlarm) => {
                const oldExceptionsSet = new Set<string>();
                oldActiveAlarm.exceptions.forEach((oldException) =>
                  oldExceptionsSet.add(oldException.message),
                );
                oldActiveAlarmsExceptions[oldActiveAlarm.id] = oldExceptionsSet;
              });
              const newExceptions: {
                alarmId: string;
                internalId: string;
                exception: RspndrException;
              }[] = [];
              newActiveAlarms.forEach((newActiveAlarm) => {
                if (oldActiveAlarmsExceptions[newActiveAlarm.id]) {
                  newActiveAlarm.exceptions.forEach((newException) => {
                    if (!oldActiveAlarmsExceptions[newActiveAlarm.id].has(newException.message)) {
                      newExceptions.push({
                        alarmId: newActiveAlarm.id,
                        internalId: newActiveAlarm.internalId,
                        exception: newException,
                      });
                    }
                  });
                } else {
                  newActiveAlarm.exceptions.forEach((newException) => {
                    newExceptions.push({
                      alarmId: newActiveAlarm.id,
                      internalId: newActiveAlarm.internalId,
                      exception: newException,
                    });
                  });
                }
              });
              const notificationPreferences: RspndrNotificationPreferences =
                await localforage.getItem('NOTIFICATION-PREFERENCES');
              const filteredExceptionsByPreference = newExceptions.filter((newException) => {
                // filter by preference for specific alarm ID if it exists
                if (notificationPreferences?.[newException.alarmId]) {
                  return notificationPreferences[newException.alarmId][
                    newException.exception.level.toLowerCase()
                  ];
                }
                // filter by default notification preference
                if (browserPrefs?.defaultAlarmNotifications) {
                  return browserPrefs.defaultAlarmNotifications?.[
                    newException.exception.level.toLowerCase()
                  ];
                }
                // if no preference exists, display notification
                return true;
              });
              if (filteredExceptionsByPreference?.length > 0) {
                filteredExceptionsByPreference.forEach(({ internalId, exception }) => {
                  Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const notification = new Notification(`RSPNDR - Incident #${internalId}`, {
                        icon: '../../assets/icon/rspndr-favicon.png',
                        body: `${_.capitalize(exception?.level)}: ${exception?.title}. ${
                          exception?.message !== exception?.title ? exception?.message : ''
                        }`,
                      });
                    }
                  });
                });
              }
            }
          },
        ),
      ),
    { dispatch: false },
  );

  inactiveBrowserEventListener$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.INACTIVE_BROWSER_LISTENER),
      mergeMap(() => {
        const userEvents$ = merge(
          fromEvent(document, 'click'),
          fromEvent(document, 'wheel'),
          fromEvent(document, 'scroll'),
          fromEvent(document, 'mousemove'),
          fromEvent(document, 'keyup'),
          fromEvent(window, 'resize'),
          fromEvent(window, 'scroll'),
          fromEvent(window, 'mousemove'),
        );
        return userEvents$.pipe(
          startWith('starting event listener...'),
          throttle(() => interval(1000)),
          switchMap(() =>
            interval(1000).pipe(
              withLatestFrom(this.store.select(getAppBrowserPreferences)),
              take(BROWSER_INACTIVE_TIME_LIMIT_SECONDS),
              mergeMap(([x, browserPrefs]) =>
                iif(
                  () =>
                    x === BROWSER_INACTIVE_TIME_LIMIT_SECONDS - 1 &&
                    browserPrefs.inactiveBrowserPollingPause,
                  throwError(() => new Error('inactive')),
                  EMPTY,
                ),
              ),
            ),
          ),
          take(1),
          catchError(() => {
            this.dialog.open(InactiveWarningDialogComponent, {
              width: '600px',
              maxWidth: '95vw',
              maxHeight: '90vh',
              disableClose: true,
              backdropClass: 'dark-backdrop',
            });
            return of(actions.activeAlarmsGuardsPollerStopAction());
          }),
        );
      }),
    ),
  );

  stopPollingOnNavigateAwayFromHome$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this.store.select(getSelectors().selectUrl)),
      mergeMap(([, currentUrl]) => {
        if (!currentUrl.includes('/home')) {
          // eslint-disable-next-line no-console
          console.log('POLL_END: End Polling');
          return of(actions.activeAlarmsGuardsPollerStopAction());
        }
        return of(actions.noOpAction());
      }),
    ),
  );

  activeAlarmsPoller$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_POLLER),
      // eslint-disable-next-line no-console
      tap(() => console.log('POLL_START: Start Active Alarms Polling')),
      delay(ACTIVE_ALARMS_POLL_INTERVAL_MS),
      withLatestFrom(this.store.select(getHomeActiveAlarmsTableError)),
      mergeMap(([, hasError]) => {
        if (hasError) {
          return of(actions.noOpAction());
        }
        return timer(0, ACTIVE_ALARMS_POLL_INTERVAL_MS).pipe(
          takeUntil(this.authService.logoutEvent),
          withLatestFrom(this.store.select(getHomeActiveAlarmsLastModifiedAt)),
          mergeMap(([, activeAlarmsLastModifiedAt]) =>
            this.portalApi
              .activeAlarms(activeAlarmsLastModifiedAt)
              .pipe(
                mergeMap((response) =>
                  of(actions.activeAlarmsSuccessAction({ payload: response[0].payload })),
                ),
              ),
          ),
          retryWhen(this.rxjsUtils.pollingBackoffStrategy({ requestType: 'active alarms data' })),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load active alarm data: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.activeAlarmsPollerFailAction());
          }),
          takeUntil(
            this.actions$.pipe(ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_GUARDS_POLLER_STOP)),
          ),
        );
      }),
    ),
  );

  activeAlarms$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS),
      withLatestFrom(this.store.select(getHomeActiveAlarmsLastModifiedAt)),
      mergeMap(([, activeAlarmsLastModifiedAt]) =>
        this.portalApi.activeAlarms(activeAlarmsLastModifiedAt).pipe(
          mergeMap((response) =>
            of(actions.activeAlarmsSuccessAction({ payload: response[0].payload })),
          ),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load active alarm data: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  guardsPoller: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.GUARDS_POLLER),
      // eslint-disable-next-line no-console
      tap(() => console.log('POLL_START: Start Guards Polling')),
      delay(GUARDS_POLL_INTERVAL_MS),
      mergeMap(() =>
        timer(0, GUARDS_POLL_INTERVAL_MS).pipe(
          takeUntil(this.authService.logoutEvent),
          withLatestFrom(
            this.store.select(getHomeGuardsLastModifiedAt),
            this.store.select(getHomeActiveAlarms),
            this.authService.isMC$,
            this.authService.isDealer$,
          ),
          mergeMap(([, guardsLastModifiedAt, activeAlarms, isMC, isDealer]) => {
            // if user is MC or DEALER, add guards who are assigned to alarms
            let assignedToAlarmUsernames: string[] = [];
            if (isMC || isDealer) {
              assignedToAlarmUsernames = activeAlarms
                .filter((alarm) => !!alarm.assignedTo?.username)
                .map((alarm) => alarm.assignedTo?.username);
            }
            return this.portalApi
              .guards(assignedToAlarmUsernames, guardsLastModifiedAt)
              .pipe(
                mergeMap((response) =>
                  of(actions.guardsSuccessAction({ payload: response[0].payload })),
                ),
              );
          }),
          retryWhen(this.rxjsUtils.pollingBackoffStrategy({ requestType: 'active guards data' })),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load guard data: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.guardsPollerFailAction());
          }),
          takeUntil(
            this.actions$.pipe(ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_GUARDS_POLLER_STOP)),
          ),
        ),
      ),
    ),
  );

  guards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.GUARDS),
      withLatestFrom(
        this.store.select(getHomeGuardsLastModifiedAt),
        this.store.select(getHomeActiveAlarms),
        this.authService.isMC$,
        this.authService.isDealer$,
      ),
      mergeMap(([, guardsLastModifiedAt, activeAlarms, isMC, isDealer]) => {
        // if user is MC or DEALER, add guards who are assigned to alarms
        let assignedToAlarmUsernames: string[] = [];
        if (isMC || isDealer) {
          assignedToAlarmUsernames = activeAlarms
            .filter((alarm) => !!alarm.assignedTo?.username)
            .map((alarm) => alarm.assignedTo?.username);
        }
        return this.portalApi.guards(assignedToAlarmUsernames, guardsLastModifiedAt).pipe(
          mergeMap((response) => of(actions.guardsSuccessAction({ payload: response[0].payload }))),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load guard data: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        );
      }),
    ),
  );

  updateGuardsOnNewIncomingAlarm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.ACTIVE_ALARMS_SUCCESS),
      map(
        (action: ReturnType<typeof actions.activeAlarmsSuccessAction>) =>
          action.payload.activeAlarmIds,
      ),
      pairwise(),
      map(([oldActiveAlarmIds, newActivealarmIds]) => {
        if (newActivealarmIds?.length > oldActiveAlarmIds?.length) {
          return actions.guardsAction();
        }
        return actions.noOpAction();
      }),
    ),
  );

  dispatchAlarm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.DISPATCH_ALARM),
      map((action: ReturnType<typeof actions.dispatchAlarmAction>) => action.incident),
      mergeMap((incident) =>
        this.integrationApi.dispatchAlarm(incident).pipe(
          mergeMap((res) => {
            if (res.success) {
              this.dialog.closeAll();
              return [
                actions.dispatchAlarmSuccessAction(),
                actions.activeAlarmsAction(),
                actions.guardsAction(),
              ];
            }
            return of(actions.dispatchAlarmFailAction({ error: new Error(res.errMsg) }));
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to dispatch alarm: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.dispatchAlarmFailAction({ error: new Error(err.message) }));
          }),
        ),
      ),
    ),
  );

  dispatchPatrol$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.DISPATCH_PATROL),
      map((action: ReturnType<typeof actions.dispatchPatrolAction>) => action.incident),
      mergeMap((incident) =>
        this.integrationApi.dispatchPatrolCheck(incident).pipe(
          mergeMap((res) => {
            if (res.success) {
              this.dialog.closeAll();
              return [
                actions.dispatchPatrolSuccessAction(),
                actions.activeAlarmsAction(),
                actions.guardsAction(),
              ];
            }
            return of(actions.dispatchPatrolFailAction({ error: new Error(res.errMsg) }));
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to dispatch patrol check: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.dispatchPatrolFailAction({ error: new Error(err.message) }));
          }),
        ),
      ),
    ),
  );

  completedAlarms$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.COMPLETED_ALARMS),
      withLatestFrom(this.store),
      mergeMap(([, state]) =>
        this.portalApi
          .completedAlarms(
            !!state.home.reportFilterDateRange ? state.home.reportFilterDateRange.start : '',
            !!state.home.reportFilterDateRange ? state.home.reportFilterDateRange.end : '',
            state.home.reportFilterText,
            true,
            0,
            state.home.reportPageSize,
            state.home.reportPageNumber,
            state.home.reportSortProperty,
            state.home.reportSortDirection.toUpperCase(), // MatSort is lowercase, Spring Data is uppercase
          )
          .pipe(
            mergeMap((alarms: Page<RspndrAlarm>) =>
              of(actions.completedAlarmsSuccessAction({ completedAlarms: alarms })),
            ),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(
                `Failed to load completed alarms: ${err.status} - ${err.statusText}`,
                'OK',
                {
                  panelClass: 'snackbar-error',
                },
              );
              return of(actions.completedAlarmsFailAction());
            }),
          ),
      ),
    ),
  );

  setReportTabTextFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.REPORT_FILTER_TEXT),
      debounceTime(500),
      map(() => actions.completedAlarmsAction()),
    ),
  );

  clearReportTabTextFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.REPORT_FILTER_TEXT_CLEAR),
      map(() => actions.completedAlarmsAction()),
    ),
  );

  setReportTabDateRangeFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.REPORT_FILTER_DATE_RANGE),
      map(() => actions.completedAlarmsAction()),
    ),
  );

  setReportPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.REPORT_CHANGE_PAGE),
      map(() => actions.completedAlarmsAction()),
    ),
  );

  setReportSort$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.REPORT_CHANGE_SORT),
      map(() => actions.completedAlarmsAction()),
    ),
  );

  guardDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.GUARD_DETAIL),
      map((action: ReturnType<typeof actions.guardDetailAction>) => action.guardId),
      mergeMap((guardId) =>
        this.usersApi.getUserById(guardId).pipe(
          mergeMap((guard: RspndrGuard) =>
            of(actions.guardDetailSuccessAction({ guardDetail: guard })),
          ),
          catchError((err: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.snackbar.open(
              `Failed to load details for guard with ID ${guardId}: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.guardDetailFailAction());
          }),
        ),
      ),
    ),
  );

  setSelectedAlarm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.ALARM_SELECT),
      map((action: ReturnType<typeof actions.alarmSelectAction>) => action.selectedAlarmId),
      mergeMap(() => [actions.guardSelectClearAction(), actions.alarmSelectSuccessAction()]),
    ),
  );

  setSelectedGuard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.HOME_ACTION_TYPES.GUARD_SELECT),
      map((action: ReturnType<typeof actions.guardSelectAction>) => action.selectedGuardId),
      withLatestFrom(
        this.store.select(getHomeGuards),
        this.authService.isRootAdmin$,
        this.authService.isOutsideOps$,
      ),
      mergeMap(([guardId, guards, isRootAdmin, isOutsideOps]) => {
        const foundGuard = guards.find((guard) => guard.id === guardId);
        // dismiss any open snackbars
        this.snackbar.dismiss();
        // Display error snackbar if location cannot be determined, or warning snackbar if location is stale
        if (!foundGuard.position?.coordinate) {
          this.snackbar.open(
            `Selection Failed: Location could not be determined for guard ${foundGuard?.username}`,
            undefined,
            {
              panelClass: 'snackbar-error',
            },
          );
          return of(actions.noOpAction());
        }
        if (
          (isRootAdmin || isOutsideOps) &&
          (!foundGuard?.position?.receivedAt ||
            moment.utc().valueOf() - foundGuard.position.receivedAt >
              POSITION_INACTIVE_THRESHOLD_MS)
        ) {
          this.snackbar.open(
            `Warning: Location data for guard ${foundGuard?.username} has not been updated for more than 30 minutes.`,
            undefined,
            { panelClass: 'snackbar-warning' },
          );
        }
        return [actions.alarmSelectClearAction(), actions.guardSelectSuccessAction()];
      }),
    ),
  );

  // create the loadSubscriberInfo$ effect with the getSubscribers method from the portalApi
  // use payload of the loadSubscriberInfo action to get the tenantId instead of the identityClaims
  loadSubscriberInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadSubscriberInfo),
      mergeMap(({ tenantId, customerNumber }) =>
        this.alarmApi.getSubscribers(tenantId, customerNumber).pipe(
          mergeMap((subscribers: RspndrSimpleSubscriberDto[]) =>
            of(actions.loadSubscriberInfoSuccess({ subscriberInfo: subscribers })),
          ),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load subscriber info: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.loadSubscriberInfoFail({ error: err }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private portalApi: RspndrPortalApi,
    private usersApi: RspndrUserApi,
    private store: Store<State>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private rxjsUtils: RxjsUtils,
    private authService: RspndrAuthService,
    private integrationApi: RspndrIntegrationApi,
    private alarmApi: RspndrAlarmApi,
  ) {}
}
