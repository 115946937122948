import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { RspndrUserApi } from '../@core/@api/user';
import * as reducers from '../@core/reducers';
import * as userMgtActions from './usermanagement.actions';
import * as homeActions from '../home/home.actions';
import { ConfirmationErrorDialogComponent } from './confirmation-error-dialog/confirmation-error-dialog.component';
import { Page, RspndrPortalApi } from '../@core/@api/portal';
import {
  ErrorSnackbarComponent,
  IErrorSnackbarData,
} from '../error-snackbar/error-snackbar.component';
import { RspndrAuthService } from '../@core/@services/auth.service';
import { RspndrGuard } from '../@core/@models/rspndr';

@Injectable()
export class UserManagementEffects {
  createUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE),
      map((action: userMgtActions.UserMgtCreateAction) => action.payload),
      withLatestFrom(
        this.authService.isGCOperator$,
        this.authService.isRootAdmin$,
        this.authService.identityClaims$,
      ),
      mergeMap(([payload, isGCOperator, isRootAdmin, claims]) => {
        let apiCall$: Observable<RspndrGuard>;

        if (isRootAdmin) {
          apiCall$ = this.userApi.createUser(payload.user, payload.tenantId);
        } else if (isGCOperator) {
          apiCall$ = this.userApi.createGuard(payload.user, claims.tenant_id);
        } else {
          apiCall$ = this.userApi.createUser(payload.user, claims.tenant_id);
        }

        return apiCall$.pipe(
          mergeMap((result) => {
            this.snackbar.open(
              `Successfully created user ${result.username}. A password reset email has been sent to the registered email.`,
              undefined,
              {
                panelClass: 'snackbar-success',
                duration: 60000,
              },
            );
            this.dialog.closeAll();
            return of(new userMgtActions.UserMgtCreateSuccessAction(result));
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Create User',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtCreateFailAction(error));
          }),
        );
      }),
    ),
  );

  updateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE),
      map((action: userMgtActions.UserMgtUpdateAction) => action.payload),
      withLatestFrom(this.authService.isGCOperator$),
      mergeMap(([payload, isGCOperator]) => {
        let apiCall$: Observable<RspndrGuard>;

        if (isGCOperator) {
          apiCall$ = this.userApi.updateGuard(payload);
        } else {
          apiCall$ = this.userApi.updateUser(payload);
        }

        return apiCall$.pipe(
          mergeMap((result) => {
            this.snackbar.open(`Successfully updated user ${result.username}.`, undefined, {
              panelClass: 'snackbar-success',
              duration: 60000,
            });
            this.dialog.closeAll();
            return of(new userMgtActions.UserMgtUpdateSuccessAction(result));
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Update User',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtUpdateFailAction(error));
          }),
        );
      }),
    ),
  );

  search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_PAGING,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SEARCH_TEXT,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_STATE,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_TYPE,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SORTING,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_REFRESH,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE_SUCCESS,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE_SUCCESS,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE_SUCCESS,
        userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE_SUCCESS,
      ),
      withLatestFrom(
        this.store$.select(reducers.getUserManagementFilterCriteria),
        this.authService.isRootAdmin$,
        this.authService.isNLOps$,
        this.authService.identityClaims$,
      ),
      mergeMap(([, filterCriteria, isRootAdmin, isNLOps, claims]) => {
        let apiCall$: Observable<Page<RspndrGuard>>;

        if (isRootAdmin) {
          apiCall$ = this.userApi.getAllUsers(filterCriteria);
        } else if (isNLOps) {
          apiCall$ = this.userApi.getNLOpsUsers(filterCriteria);
        } else {
          apiCall$ = this.userApi.getUsersByTenantId(claims.tenant_id, filterCriteria);
        }

        return apiCall$.pipe(
          mergeMap((result) => of(new userMgtActions.UserMgtSearchSuccessAction(result))),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Load Users List',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtSearchFailAction(error));
          }),
        );
      }),
    ),
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE),
      map((action: userMgtActions.UserMgtDeleteAction) => action.payload),
      mergeMap((id) =>
        this.userApi.deleteUser(id).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully deleted user.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(new userMgtActions.UserMgtDeleteSuccessAction(id));
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Delete User',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtDeleteFailAction(error));
          }),
        ),
      ),
    ),
  );

  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_RESET_PASSWORD),
      map((action: userMgtActions.UserMgtResetPasswordAction) => action.payload),
      withLatestFrom(this.store$, this.authService.isNLOps$, this.authService.isGCOperator$),
      switchMap(([payload, state, isNLOps, isGCOperator]) => {
        let apiCall$: Observable<void>;

        if (isNLOps) {
          apiCall$ = this.userApi.nlOpsTriggerPasswordResetEmail(payload.userId);
        } else if (isGCOperator) {
          apiCall$ = this.userApi.gcOperatorsTriggerPasswordResetEmailForGuardOnly(payload.userId);
        } else {
          apiCall$ = this.userApi.triggerPasswordResetEmail(payload.userId);
        }

        return apiCall$.pipe(
          mergeMap(() => {
            this.dialog.open(ConfirmationErrorDialogComponent, {
              width: '400px',
              maxWidth: '95vw',
              data: {
                title: state.userManagement.pwdResetTitle,
                subTitle: 'Email Sent',
                message:
                  'Please inform the user to check their email for a link to update their password.',
              },
            });

            return of(new userMgtActions.UserMgtResetPasswordSuccessAction());
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Reset User Password',
                  error: errorResponse,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtResetPasswordFailAction(errorResponse));
          }),
        );
      }),
    ),
  );

  enable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE),
      map((action: userMgtActions.UserMgtEnableAction) => action.payload),
      mergeMap((payload) =>
        this.userApi.toggleEnableUser(payload.userId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully toggled user enable/disable', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(new userMgtActions.UserMgtEnableSuccessAction(payload));
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Enable/Disable User',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtEnableFailAction(error));
          }),
        ),
      ),
    ),
  );

  logout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_LOGOUT),
      map((action: userMgtActions.UserMgtLogoutAction) => action.payload),
      withLatestFrom(this.authService.isNLOps$, this.authService.isGCOperator$),
      mergeMap(([payload, isNLOps, isGCOperator]) => {
        let apiCall$: Observable<void>;

        if (isNLOps) {
          apiCall$ = this.userApi.nlOpsLogoutUser(payload.userId);
        } else if (isGCOperator) {
          apiCall$ = this.userApi.gcOperatorLogoutUserForGuardOnly(payload.userId);
        } else {
          apiCall$ = this.userApi.logoutUser(payload.userId);
        }

        return apiCall$.pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully logged out user', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.guardDetailClearAction());
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Logout User',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtNoopAction());
          }),
        );
      }),
    ),
  );

  guardCacheClear$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userMgtActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_GUARD_CACHE_CLEAR),
      withLatestFrom(this.store$),
      filter(([, state]) => !!state.home?.guardDetail?.username),
      map(([, state]) => state.home.guardDetail.username),
      mergeMap((guardUsername) =>
        this.portalApi.clearGuardCache(guardUsername).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully cleared guard cache', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(new userMgtActions.UserMgtGuardCacheClearSuccessAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to clear guard cache: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(new userMgtActions.UserMgtGuardCacheClearFailAction(err));
          }),
        ),
      ),
    ),
  );

  constructor(
    private store$: Store<reducers.State>,
    private actions$: Actions,
    private userApi: RspndrUserApi,
    private portalApi: RspndrPortalApi,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private authService: RspndrAuthService,
  ) {}
}
